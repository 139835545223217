import { message } from 'antd';
import i18n from '../i18n';
import {EventList} from './constraints'
import { TYPES } from "./types";
const {paginationProps, FilterVideos, showDivsConditions, loadingDeleteShowVideo} = EventList()
// Extract filter parameter from the URL
const urlParams = new URLSearchParams(window.location.search); 
const filter = urlParams.get('filter'); // Get the value of the 'filter' query parameter
const INITIALSTATE = {
  ListVideos: [],
  pagination: paginationProps(),
  FilterVideos: FilterVideos(),
  showDivsCondition: showDivsConditions(),
  lang: localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "fr",
  loadingDelete: loadingDeleteShowVideo(),
  logo: "",
  urlLogo: "",
  isCropped: true,
  setLoader: false,
  selectedYTB:false,
  links:"",
  deleteMultiEvents:false,
  onConfirmMultiDelete: null,
  currentStatus: filter ?? "live"
 };

export const reducer = (state = INITIALSTATE, action) => {

    switch (action.type) {
        case "SET_ShowVideos":
            return {
                ...state,
                ListVideos: action.payload
            }
            case "CURRENT_STATUS":{
                return{
                    ...state,
                    currentStatus: action.payload
                }
            }
        case "SET_PaginationProps" :
            const {PaginationPropsNameChange, PaginationPropsValueChange} = action.payload
            return {
                ...state,
                pagination: {...state.pagination, [PaginationPropsNameChange]: PaginationPropsValueChange}
            }
        case "SET_FilterVideos" :
            const {FilterVideosNameChange, FilterVideosValueChange} = action.payload
            const FilterVideosOnOnchangeObj = {...state.FilterVideos, [FilterVideosNameChange]: FilterVideosValueChange}
            return {
                ...state,
                FilterVideos: FilterVideosOnOnchangeObj
            }

        case "SET_ConfirmMultiDelete":
             return {
                ...state,
                onConfirmMultiDelete : action.payload
            }

       case "SET_DeleteMultiEvents":
             return {
                ...state,
                deleteMultiEvents:  action.payload
              }


        case "SET_showDivsConditions":
            const {showDivsConditionsName, showDivsConditionsValue} = action.payload
            const showDivsConditionsObj = {
                ...state.showDivsCondition,
                [showDivsConditionsName]: showDivsConditionsValue
            }
            return {
                ...state,
                showDivsCondition: showDivsConditionsObj
            }
        case "CHANGE_LANG":
            return {...state, lang: action.payload}
        case "SET_LoadingDeleteShowVideo":
            const {LoadingDeleteName, LoadingDeleteValue} = action.payload
            const LoadingDeleteObj = {...state.loadingDelete, [LoadingDeleteName]: LoadingDeleteValue}
            return {
                ...state,
                loadingDelete: LoadingDeleteObj
            }
        case "UPLOAD_LOGO_THEME":
            return {
                ...state,
                logo : action.payload
            }
        case "UPLOAD_LOGO_URL":
            return {
                ...state,
                urlLogo:action.payload
            }    
        case "SET-CROPPED":
            return {
                ...state,
                isCropped:action.payload
            }
        case "SET-LOADER":
            return{
                ...state,
                setLoader:action.payload
            }
        case "SET_SELECTED_YTB":
            return {
              ...state,
              selectedYTB: action.payload,
            };  
        case "SET_LINKS":
            return {
                ...state,
                links:action.payload
            }
        case TYPES.START_EVENT_SUCCESS:
            const updatedStartListVideos = state.ListVideos.data.map(video => {
                    if (video.id === action.payload.id) {
                      return { ...video, status: 0 };
                    }
                    return video;
            });
            message.success({
                content: i18n.t('Message.startEvent',{id:action.payload.id}) ,
                duration:2
              });
            return {
                    ...state,
                    ListVideos: { ...state.ListVideos, data: updatedStartListVideos },
                }
            
        case TYPES.STOP_EVENT_SUCCESS:
            const updatedListVideos = state.ListVideos.data.map(video => {
            if (video.id === action.payload.id) {
                      return { ...video, status: 1 };
            }
            return video;
            });
            message.success({
                content: i18n.t('Message.stopEvent',{id:action.payload.id}) ,
                duration:2
              });
            return {
                    ...state,
                    ListVideos: { ...state.ListVideos, data: updatedListVideos },
            }         
            default: {
            return state
        }

    }


}