import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  LinkOutlined,
  EditOutlined,
  BarChartOutlined,
  DesktopOutlined,
  MoreOutlined,
  UndoOutlined,
  PlaySquareOutlined
} from "@ant-design/icons";
import { Button ,Menu, Modal} from "antd";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { graphQL_shema } from "../page/utils/graphql";
import { useMutation } from "@apollo/client";
import { endEventFlow, endEventRec, startEventRec } from "../redux/actions";
import { useDispatch } from "react-redux";
import MenuMore from "./MenuMore";
import { GraphQLFetchData } from "../page/utils/graphQLFetchData";
import axios from "axios";
import { initSocketLive } from "../page/utils/socket";
import { ReactComponent as PlayGreen } from './../assests/playGreen.svg';
import { ReactComponent as StopRed } from './../assests/stopRed.svg';
import { ReactComponent as PlayBlue } from './../assests/playBlue.svg';
const UseActionMenu = ({
  handleDelete,
  record,
  showModaltabletoaction,
  takeIdVideoTabletoaction,
  refetchEvents,
  GetTrashedEvents,
  handleRestore,
  openModalFlux,
  modalContent
}) => {
  const history = useHistory();
  const { event_module } = useSelector(
    (state) => state.ReducerUserData.modules
  );
  const dispatch = useDispatch();

  const { t } = useTranslation(); // translation
  const [startEvent] = useMutation(graphQL_shema().START_EVENT, {
    onCompleted: (data) => {
      if (data.updateEventOrganizerStatus.code ==="200"){
        dispatch(startEventRec(record));
        handleSocketStart(record.id);

      }
    },
  });
  const { restoreMutation} = GraphQLFetchData();
  const searchParams = new URLSearchParams(window.location.search);
  const evenement = searchParams.get("filter"); // language params
  const [endEvent] = useMutation(graphQL_shema().START_EVENT, {
    onCompleted: (data) => {
      if (data.updateEventOrganizerStatus.code ==="200"){
        dispatch(endEventRec(record));
        dispatch(endEventFlow(record));
        handleSocketEnd(record.id);


      }
    },
  });
  const handleDiffusion = () => {
    switch (record.status) {
      case -1:
        startEvent({
          variables: {
            id: record.id,
            status: 0,
          },
        });
        break;
      case 0:
        endEvent({
          variables: {
            id: record.id,
            status: 1,
          },
        });
        break;

      default:
        startEvent({
          variables: {
            id: record.id,
            status: 0,
          },
        });
        break;
    }
  };
  const handleSocketStart = async (eventId) => {
    try {
      await initSocketLive(eventId,0);
  } catch (error) {
      console.error("Failed to start the socket:", error);
  }
  };

  const handleSocketEnd = async (eventId) => {
    try {
      await initSocketLive(eventId,1);
    } catch (error) {
      console.error("Failed to start the socket:", error);
  }
  };


  const handleOpenFlux = async () => {
    const token = localStorage.getItem('authToken');
    const url = `https:${window.process.env.API_PLAYER}/api/video/video/crypt-url`
    const params = {
      v: record.conferenceKey,
      y: 'bo',
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let response;

    try {
      response = await axios.get(url, { params, headers });
    } catch (error) {
      console.error('Erreur lors de la requête :', error);
    }

    if (response) {
      const iframe = (
        <iframe src={response.data.cryptedURL} className="flow-iframe" width="100%" height="100%" style={{aspectRatio: "16/9"}}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                webkitallowfullscreen mozallowfullscreen frameBorder="0"></iframe>
      )
      modalContent(iframe)
      openModalFlux(true)
    }
    
  }

  const handleMenuClick = () => {
    history.push(`/events/editevent/${record.id}`, {
      record: record,
      action: "update",
    });

    window.parent.postMessage(
      {
        info: "event_edit",
        value: "edit",
      },
      "*"
    );
  };
  // fonction pour afficher le modal d'embed code
  const handleCode = () => {
    showModaltabletoaction(true, record);
    takeIdVideoTabletoaction(record.idVideo);
  };
  // statistic Route
  const handleStatistic = () => {
    window.parent.postMessage(
      {
        info: "showEventStat",
        value: record.id,
      },
      "*"
    );
  };
 
  
 
  const menuAction = (
    <div className={"MenuAction"}>

  <>
        <Button
          key="diffusion"
          onClick={handleDiffusion}
          disabled={record.status===1 || record.isAuto}
          style={{
            color:
              record.status === 0 ? "green" : record.status === 1 ? "red" : "",
          }}

          icon={
            record.status === 0 ? (
              <PlayGreen />
            ) : record.status === 1 ? (
              <StopRed />
            ) : (
              <PlayBlue />
            )
          }
        />

        <span className="EditAction">{t("Action.diffuser")}</span>
      </>

      {event_module?.event_flux_live_no_api?.authorized && (
      <>
             <Button
            key="flux"
            has-permission="event_flux_live_no_api"
            onClick={handleOpenFlux}
          icon={<PlaySquareOutlined />}
        />
  
          <span className="EditAction">{t("Action.flux")}</span>
        </>
      )}
        
   

      {event_module?.event_post_edit_action?.authorized && (
        <>
             <Button
            key="edit"
            has-permission="event_post_edit"
            onClick={handleMenuClick}
          icon={<EditOutlined />}
        />
  
          <span className="EditAction">{t("Action.modifier")}</span>
        </>
      )}
      {event_module?.event_get_export_action?.authorized ? (
        <>
               <Button
            key="edit"
            has-permission="event_get_export"
            onClick={() => handleCode(record)}
          icon={<LinkOutlined />}
        />

          <span className="LinkAction">{t("Action.lienExport")}</span>
        </>
      ) : null}

      {event_module?.event_delete_event_live_action?.authorized ? (
        <>
                  <Button
            has-permission="event_delete_event_live"
            onClick={() => handleDelete(record)}
          icon={<DeleteOutlined />}
        />
          <span className="DeleteAction">{t("Action.Supprimer")}</span>
        </>
      ) : null}

       {event_module && evenement === "trash" ? (
        <>
                  <Button
            has-permission="event_delete_event_live"
            onClick={() => handleRestore(record)}
          icon={<UndoOutlined />}
        />
          <span className="DeleteAction">{t("Action.Supprimer")}</span>
        </>
      ) : null}
      {event_module?.event_stats_no_api_action?.authorized ? (
        <>
                <Button
            has-permission="event_stats_no_api"
            onClick={handleStatistic}
          icon={<BarChartOutlined />}
        />
   
          <span className="StatisticAction">{t("Action.Statistiques")}</span>
        </>
      ) : null}
    </div>
  );
  const size = useMediaQuery("(max-width: 600px)");
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const handleOpen = (e) => {
		setAnchor(e.target);
	};
	const handleClose = () => setAnchor(null);

  return (
    <>
      <div className="div_tooltip_action" id="content-desktop">

        {size ? 
          <>
          <Button
          className="moreIcon"
          onClick={handleOpen}
          icon={<MoreOutlined />}/>
          <MenuMore anchor={anchor} open={open} close={handleClose} menu={menuAction}/>
          </> 
          :  
          menuAction
         }
      </div>
    </>
  );
};

export default UseActionMenu;
